import classname from 'classnames';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import Text from 'bloko/blocks/text';

import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import useIndustries from 'lux/pages/EmployersCompany/hooks/industries';

import styles from './styles.less';

const List: React.FC = () => {
    const employers = useSelectorNonNullable((state) => state.employersCompany.employers);
    const { industry, subIndustry } = useIndustries();

    if (!employers) {
        return null;
    }

    return (
        <div
            className={classname(styles.employersList, {
                [styles.subIndustries]: industry && !subIndustry,
            })}
        >
            {employers.list.map((employer) => (
                <div className={styles.employer} key={employer.id}>
                    <Link to={`/employer/${employer.id}`}>{employer.name}</Link>
                    {NON_BREAKING_SPACE}
                    <Text>{employer.vacanciesOpen}</Text>
                </div>
            ))}
        </div>
    );
};

export default List;
