import { useCallback } from 'react';

import Conversion from 'bloko/blocks/conversion';
import Link, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    more: 'employers.company.industries.more',
    one: 'employers.company.subindustry.one',
    some: 'employers.company.subindustry.many',
    many: 'employers.company.subindustry.some',
    inIndustry: 'employers.company.in.this.industry',
};

interface ExpandLinkProps {
    value: number;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExpandLink: TranslatedComponent<ExpandLinkProps> = ({ value, setIsExpanded, trls }) => {
    const handleLinkClick = useCallback(() => {
        setIsExpanded(true);
    }, [setIsExpanded]);

    return (
        <Link appearance={LinkAppearance.Pseudo} kind={LinkKind.Secondary} onClick={handleLinkClick}>
            <>{`${trls[TrlKeys.more]} `}</>
            <Conversion value={value} one={trls[TrlKeys.one]} some={trls[TrlKeys.some]} many={trls[TrlKeys.many]} />
            <>{` ${trls[TrlKeys.inIndustry]}`}</>
        </Link>
    );
};

export default translation(ExpandLink);
