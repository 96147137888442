import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import { EmployersCompanyIndustry } from 'lux/models/employersCompany';

const useIndustries: () => { industry?: EmployersCompanyIndustry; subIndustry?: EmployersCompanyIndustry } = () => {
    const industries = useSelectorNonNullable((state) => state.employersCompany.industries);
    const { industryPath, subIndustryPath }: { industryPath?: string; subIndustryPath?: string } = useParams();

    const { industry, subIndustry } = useMemo(() => {
        const subIndustry = industries.find((industry) => industry.translit === subIndustryPath);
        const industry = industries.find((industry) => industry.translit === industryPath);

        return { industry, subIndustry };
    }, [industries, industryPath, subIndustryPath]);

    return {
        industry,
        subIndustry,
    };
};

export default useIndustries;
