import { useMemo } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { H1Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { PageHead, PageTitle, PageDescription, PageMeta } from 'lux/components/PageHead';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';
import useIndustries from 'lux/pages/EmployersCompany/hooks/industries';

import AreaSelection from 'lux/pages/EmployersCompany/components/NavigationTitle/AreaSelection';

const TrlKeys = {
    default: 'employers.company.by.industries',
    industry: 'employers.company.by.industry',
    subIndustry: 'employers.company.by.subindustry',
};

const NavigationTitle: TranslatedComponent = ({ trls }) => {
    const area = useSelectorNonNullable((state) => state.employersCompany.area);
    const { industry, subIndustry } = useIndustries();
    const { description, title } = useSelector((state) => state.pageMetaData);

    const metaData = useMemo(() => {
        let trlText;
        if (subIndustry) {
            trlText = `${trls[TrlKeys.subIndustry]} ${subIndustry.title} ${area.locativeCase}`;
            return {
                title: trlText,
                header: trlText,
            };
        }

        if (industry) {
            trlText = `${trls[TrlKeys.industry]} ${industry.title} ${area.locativeCase}`;
            return {
                title: trlText,
                header: trlText,
            };
        }

        return {
            title,
            header: `${trls[TrlKeys.default]} ${area.locativeCase}`,
        };
    }, [area.locativeCase, industry, subIndustry, title, trls]);

    return (
        <>
            <PageHead>
                <PageTitle>{metaData.title}</PageTitle>
                <PageMeta property="og:title" content={metaData.title} />
                <PageDescription content={description} openGraphContent={description} />
            </PageHead>
            <H1Section data-qa="employers-company__title">{metaData.header}</H1Section>
            <VSpacing base={3} />
            <AreaSelection />
        </>
    );
};

export default translation(NavigationTitle);
