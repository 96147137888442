import classNames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Breadcrumbs from 'lux/components/Breadcrumbs';
import Breadcrumb from 'lux/components/Breadcrumbs/Breadcrumb';
import BreadcrumbsDivider from 'lux/components/Breadcrumbs/BreadcrumbsDivider';
import translation from 'lux/components/translation';
import { EmployersCompanyIndustry } from 'lux/models/employersCompany';

import styles from './styles.less';

const TrlKeys = {
    all: 'employers.company.industries.all',
};

interface IndustriesBreadcrumbsProps {
    industry?: EmployersCompanyIndustry;
    subIndustry?: EmployersCompanyIndustry;
}

const IndustriesBreadcrumbs: TranslatedComponent<IndustriesBreadcrumbsProps> = ({ industry, subIndustry, trls }) => {
    return (
        <div className={classNames(styles.breadcrumbs, { [styles.separator]: subIndustry })}>
            <Breadcrumbs>
                <Breadcrumb to="/employers_company">{trls[TrlKeys.all]}</Breadcrumb>
                {industry && (
                    <>
                        <BreadcrumbsDivider />
                        <Breadcrumb to={`/employers_company/${industry.translit}`}>{industry.title}</Breadcrumb>
                    </>
                )}
                {industry && subIndustry && (
                    <>
                        <BreadcrumbsDivider />
                        <Breadcrumb to={`/employers_company/${industry.translit}/${subIndustry.translit}`}>
                            {subIndustry.title}
                        </Breadcrumb>
                    </>
                )}
            </Breadcrumbs>
        </div>
    );
};

export default translation(IndustriesBreadcrumbs);
