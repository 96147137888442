import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import VSpacing from 'bloko/blocks/vSpacing';

import Pagination from 'lux/components/Pagination';

import Header from 'lux/pages/EmployersCompany/components/Employers/Header';
import List from 'lux/pages/EmployersCompany/components/Employers/List';

const Employers: React.FC = () => {
    const employers = useSelectorNonNullable((state) => state.employersCompany.employers);

    return (
        <>
            <Header />
            {employers && Boolean(employers?.list.length) && (
                <>
                    <VSpacing base={5} />
                    <List />
                    <VSpacing base={7} />
                    <Pagination {...employers.paging} />
                </>
            )}
        </>
    );
};

export default Employers;
