import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import PageLayout, { EmptyLayout } from 'lux/components/PageLayout';
import RowContent from 'lux/components/RowContent';

import Employers from 'lux/pages/EmployersCompany/components/Employers';
import Industries from 'lux/pages/EmployersCompany/components/Industries';
import NavigationTitle from 'lux/pages/EmployersCompany/components/NavigationTitle';
import RightBanners from 'lux/pages/EmployersCompany/components/RightBanners';
import TopLinks from 'lux/pages/EmployersCompany/components/TopLinks';

const EmployersCompany: React.FC = () => {
    return (
        <PageLayout layout={EmptyLayout}>
            <ColumnsWrapper>
                <RowContent isShrinked>
                    <Column xs="4" s="8" m="9" l="13">
                        <TopLinks />
                        <VSpacing base={7} />
                    </Column>
                    <Column xs="4" s="8" m="9" l="13">
                        <NavigationTitle />
                        <VSpacing base={5} />
                        <Industries />
                        <Employers />
                    </Column>
                    <Column s="3" m="3" l="3">
                        <RightBanners />
                    </Column>
                </RowContent>
            </ColumnsWrapper>
        </PageLayout>
    );
};

export default EmployersCompany;
